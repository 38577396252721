import React from "react";
// import Carousel from "react-bootstrap/Carousel";
import Container from "react-bootstrap/Container";

import Header from "./common/Header";
import Footer from "./common/Footer";

export default class Home extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column h-100">
        <Header />
        <main className="flex-shrink-0">
          <Container fluid="lg">
            {/* About Us */}
            <div className="h5 my-3" id="about-us">
              Welcome to Uvaan Foods
            </div>
            <p>
              Uvaan Foods is a FoodTech Startup company registered in Bengaluru,
              India. With the use of emerging technologies, including web and
              mobile app-based services, we are here to transform the way that
              the food industry operates!
            </p>
            <p>
              To begin with, we aim to connect home-made-food lovers and home
              chefs on one platform to serve freshly made Hygiene food to the
              community.
            </p>

            {/* What We Do */}
            <div className="h5 mt-4 mb-3" id="what-we-do">
              What We Do
            </div>
            <p>We are building CheffyHub!</p>
            <div className="my-2">
              <a
                href="http://cheffyhub.com"
                className="d-flex align-items-center"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <img src="/CheffyHub.png" alt="Logo" height="120px" />
                <span
                  style={{
                    fontFamily: "Montserrat",
                    fontSize: "28px",
                    color: "#000",
                    paddingLeft: "16px",
                  }}
                >
                  CheffyHub
                </span>
              </a>
            </div>

            {/* Contact Us */}
            <div className="h5 my-3 pt-4" id="contact-us">
              Contact Us
            </div>
            <p>
              Email us:{" "}
              <a href="mailto:info@uvaanfoods.com">info@uvaanfoods.com</a>
            </p>
            <p>
              Drop by: #175 & #176, C/o 91Springboard, 4th Floor, Dollars Colony, JP Nagar 4th
              Phase, Bannerghatta Main Road, Bengaluru, Karnataka 560076
            </p>
            <iframe
              title="contact-us-map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d19296.481239971166!2d77.59045331220356!3d12.903873235722099!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1519380fc7ad%3A0x8195f8645dab7f8c!2s91springboard!5e0!3m2!1sen!2sin!4v1655113164597!5m2!1sen!2sin"
              style={{ height: "480px", width: "100%" }}
            ></iframe>
          </Container>
        </main>
        <Footer />
      </div>
    );
  }
}
