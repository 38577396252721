import React from "react";
import { FaTwitter, FaFacebookF, FaInstagram } from "react-icons/fa";

export default class Footer extends React.Component {
  render() {
    return (
      <footer
        className="footer mt-auto py-3"
        style={{ backgroundColor: "#FF8A29" }}
      >
        <div className="container">
          {/* <small style={{color: '#fff'}}>© 2022 - {new Date().getFullYear()} Uvaan Foods Private Limited</small> */}
          <small style={{ color: "#fff" }}>
            © 2022 Uvaan Foods Private Limited
          </small>
          <div className="float-end">
            <a
              href="https://twitter.com/CheffyHub"
              alt="twitter"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter style={{ color: "#fff" }} />
            </a>{" "}
            <a
              href="https://www.facebook.com/CheffyHub"
              alt="facebook"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF style={{ color: "#fff" }} />
            </a>{" "}
            <a
              href="https://www.instagram.com/CheffyHub"
              alt="instagram"
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram style={{ color: "#fff" }} />
            </a>
          </div>
        </div>
      </footer>
    );
  }
}
