import React from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default class Header extends React.Component {
  render() {
    return(
      <div className="header">
        <Container>
          <div className="my-2">
            <a href="/" className="d-flex align-items-center" style={{textDecoration: 'none'}}>
              <img src="/Uvaan-Logo.png" alt="Logo" height="56px" />
              <span style={{fontFamily: 'Volkhov', fontSize: '28px', color: '#FF7808', paddingLeft: '16px'}}>Uvaan Foods</span>
            </a>
          </div>
        </Container>
        <Navbar expand="lg" style={{backgroundColor: '#FF8A29'}}>
          <Container>
            <Nav.Link href="/" className="px-3" style={{fontWeight: '500', color: '#fff'}}>HOME</Nav.Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/#what-we-do" className="px-3" style={{fontWeight: '500', color: '#fff'}}>WHAT WE DO</Nav.Link>
                <Nav.Link href="/careers" className="px-3" style={{fontWeight: '500', color: '#fff'}}>CAREERS</Nav.Link>
                <Nav.Link href="/#about-us" className="px-3" style={{fontWeight: '500', color: '#fff'}}>ABOUT US</Nav.Link>
                <Nav.Link href="/#contact-us" className="px-3" style={{fontWeight: '500', color: '#fff'}}>CONTACT US</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    )
  }
}