import React from 'react';
import Container from 'react-bootstrap/Container';

import Header from './common/Header';
import Footer from './common/Footer';

export default class Home extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column h-100">
        <Header />
        <main className="flex-shrink-0 mt-4">
          <Container fluid="lg">
            <p>Uvaan Foods is hiring!</p>
            <ul>
              <li className="mb-3">Marketing Executive with 2+ years of experience</li>
              <li className="mb-3">Telecaller with 2+ years of experience</li>
            </ul>
            <p className="mt-5">Send your resumes to <a href="mailto:careers@uvaanfoods.com">careers@uvaanfoods.com</a></p>
          </Container>
        </main>
        <Footer />
      </div>
    )
  }
}
